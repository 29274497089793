<template>
  <div class="secondcarDetail" ref="CARMAIN">
    <van-swipe ref="SWIPER" class="my-swipe" :autoplay="isAutoPlay" indicator-color="white" @change="changeSwipe">
      <van-swipe-item v-for="item in detailForm.carImage" :key="item.url">
        <video 
          ref="videoPlayer"
          v-if="item.url.indexOf('mp4') > -1" 
          :src="item.url"
          @ended="endVideo" 
          autoplay 
          muted 
          poster="@/assets/image/videoimg.png"
          controls="controls" 
          webkit-playsinline=“true” 
          playsinline=“true” 
          x5-video-orientation=“landscape|portrait” 
          x5-video-player-type=“h5-page”
        />
        <van-image
          v-else
          fit="contain"
          :src="item.url"
        />
        
      </van-swipe-item>
      <template #indicator>
        <div class="custom-indicator">{{ current + 1 }}/{{detailForm.carImage.length}}</div>
      </template>
    </van-swipe>
    <div class="carCenter" ref="CENTER">
      <p class="title">{{detailForm.carName}}</p>
      <p class="years">{{detailForm.carRegisterAt ? detailForm.carRegisterAt.slice(0, 4) : ''}}年/{{detailForm.carMileage}}万公里/{{detailForm.carCityLan}}</p>
      <div class="priceDetail">
        <span class="priceIcon">￥</span>
        <span class="priceNum">{{detailForm.carDownPrice}}</span>
        <span class="unit">万</span>
        <span class="compare">比市场价低2100元</span>
      </div>
    </div>
    <div class="carParameter" :style="'height:'+ tabH + 'px'">
      <p class="title">车辆信息</p>
      <div class="paeameterBox">
        <span class="name">品牌</span>
        <span class="explainDetail">{{detailForm.carBrand ? detailForm.carBrand : '暂无' }}</span>
      </div>
      <div class="paeameterBox">
        <span class="name">车源地</span>
        <span class="explainDetail">{{detailForm.carCity ? detailForm.carCity : '暂无'}}</span>
      </div>
      <div class="paeameterBox">
        <span class="name">轴距</span>
        <span class="explainDetail">{{detailForm.carWheelbase ? detailForm.carWheelbase : '暂无'}}</span>
      </div>
      <div class="paeameterBox">
        <span class="name">变速箱</span>
        <span class="explainDetail">{{detailForm.carGearbox ? detailForm.carGearbox : '暂无'}}</span>
      </div>
      <div class="paeameterBox">
        <span class="name">发动机</span>
        <span class="explainDetail">{{detailForm.carEngine ? detailForm.carEngine : '暂无'}}</span>
      </div>
      <div class="paeameterBox">
        <span class="name">排放标准</span>
        <span class="explainDetail">{{detailForm.carEmission ? detailForm.carEmission : '暂无'}}</span>
      </div>
    </div>
    <a class="phoneGet" :href="`tel: ${detailForm.sellerPhone}`">咨询卖家配置</a>
  </div>
</template>
<script>
import { carDetail } from '@/api/secondSelect'
export default {
  data() {
    return {
      current: 0,
      id: 0,
      tabH: 0,
      detailForm: {
        carImage: []
      },
      isAutoPlay: 3000
    }
  },
  created() {
    this.id = this.$route.query.id
    this.initData()
  },
  mounted() {
    this.tabH = this.$refs.CARMAIN.offsetHeight - this.$refs.CENTER.offsetHeight - 344
  },
  methods: {
    initData() {
      carDetail({id: this.id}).then(res => {
        if (res.success) {
          this.detailForm = res.data
          if (this.detailForm.carVideo.length > 0) {
            this.detailForm.carImage = this.detailForm.carImage.concat(this.detailForm.carVideo)
          }
        }
      }).catch(() => {

      })
    },
    changeSwipe(e) {
      if (this.detailForm.carImage[e].url.indexOf('mp4') > -1) {
        this.isAutoPlay = 0
      } else {
        this.isAutoPlay = 3000
      }
    },
    endVideo(e) {
      if (e.isTrusted) {
        this.isAutoPlay = 10
        this.$refs.videoPlayer[0].currentTime = 0;
      } else {
        this.isAutoPlay = 0
      }
    },
  }
}
</script>

<style scoped lang="scss">
.secondcarDetail{
  width: 100%;
  height: 100%;
  background: linear-gradient( 90deg, #F4F8FB 0%, #FAF6F5 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  .van-swipe{
    width: 100%;
    height: 204px;
    .van-swipe-item {
      width: 100%;
      height: 204px;
      color: #fff;
      font-size: 20px;
      line-height: 204px;
      text-align: center;
      background-color: rgb(57, 169, 237, .1);
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        height: 204px;
      }
      video{
        height: 204px;
      }
    }
  }
  .custom-indicator {
    position: absolute;
    right: 5px;
    bottom: 5px;
    padding: 2px 5px;
    font-size: 12px;
    color: #fff;
    background: rgba(0, 0, 0, 0.1);
  }
  .carCenter {
    width: 343px;
    min-height: 82px;
    margin-top: 12px;
    padding: 10px;
    background: #FFFFFF;
    border-radius: 11px 11px 11px 11px;
    .title{ 
      width: 338px;
      height: 25px;
      white-space: nowrap; /* 不换行 */
      overflow: hidden; /* 超出部分隐藏 */
      text-overflow: ellipsis; /* 显示省略号 */
      font-family: PingFang SC, PingFang SC;
      font-weight: 800;
      font-size: 18px;
      color: #212121;
    }
    .years{
      margin-top: 2px;
      font-family: PingFang SC, PingFang SC;
      font-weight: bold;
      font-size: 10px;
      color: #707070;
    }
    .priceDetail{
      width: 100%;
      display: flex;
      align-items: flex-end;
      margin-top: 23px;
      .priceIcon{
        margin-bottom: 2px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 800;
        font-size: 12px;
        color: #ED5748;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
      .priceNum{
        font-family: PingFang SC, PingFang SC;
        font-weight: 800;
        font-size: 18px;
        color: #ED5748;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
      .unit{
        margin-left: 2px;
        margin-bottom: 2px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 800;
        font-size: 10px;
        color: #ED5748;
        font-style: normal;
        text-transform: none;
      }
      .compare{
        width: 100%;
        font-family: PingFang SC, PingFang SC;
        font-weight: bold;
        font-size: 10px;
        color: #ED5748;
        text-align: right;
      }
    }
  }
  .carParameter{
    width: 343px;
    overflow-y: scroll;
    margin-top: 10px;
    padding: 10px 10px 0 10px;
    background: #FFFFFF;
    border-radius: 11px 11px 11px 11px;
    .title{
      font-family: PingFang SC, PingFang SC;
      font-weight: 800;
      font-size: 18px;
      color: #212121;
      font-style: normal;
      text-transform: none;
    }
    .paeameterBox{
      width: 342px;
      min-height: 53px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #F5F5F5;
      .name{
        width: 50px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 800;
        font-size: 12px;
        color: #707070;
        text-align: right;
        font-style: normal;
        text-transform: none;
      }
      .explainDetail{
        width: 240px;
        margin-left: 25px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 800;
        font-size: 12px;
        color: #000000;
      }
    }
  }
  .phoneGet{
    width: 350px;
    height: 44px;
    margin-top: 12px;
    background: linear-gradient( 90deg, #6493FF 0%, #477EFE 100%);
    border-radius: 22px 22px 22px 22px;
    font-family: PingFang SC, PingFang SC;
    font-weight: bold;
    font-size: 18px;
    line-height: 44px;
    text-align: center;
    color: #FFFFFF;
    letter-spacing: 2px;
    font-style: normal;
    text-transform: none;
  }
}
</style>
